<template>
    <transition name="slide">
        <div class="search-modal" v-if="visible">
            <form @submit.prevent="submit">
                <label>
                    <input type="text" name="search" v-model="search" autocomplete="off" placeholder="Введіть слово" ref="search">
                </label>
                <button></button>
            </form>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'SearchModal',
    props: {
        visible: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            search: ''
        }
    },
    watch: {
        visible() {
            if(this.visible) {
                window.addEventListener("keyup", this.keyboardListener, false);
                this.inputFocus();
            }else{
                window.removeEventListener("keyup", this.keyboardListener, false);
            }
        }
    },
    methods: {
        inputFocus() {
            setTimeout(() => {
                this.$refs.search.focus();
            });
        },
        submit() {
            if(this.$route.name !== 'search') {
                this.$router.push({ name: 'search', query: { search: this.search } });
                this.$emit('close');
            }else{
                location.search = `?search=${this.search}`;
            }
        },
        keyboardListener(e) {
            if (e.keyCode === 27) {
                this.$emit('close');
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .slide-enter-active, .slide-leave-active {
        transition: opacity .35s, transform .35s;
    }
    .slide-enter, .slide-leave-to{
        opacity: 0;
        transform: scaleY(.7);
    }
    .search-modal{
        position: absolute;
        transform-origin: top center;
        width: 100%;
        left: 0;
        z-index: 99;
        top: 100%;
        padding: 80px;
        box-sizing: border-box;
        @media all and(max-width: 550px) {
            padding: 40px 20px;
        }
        &:before{
            content: '';
            display: block;
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #1a2744;
            opacity: .9;
        }
        form{
            position: relative;
            margin: 5.95% auto 0;
            z-index: 2;
            max-width: 1200px;
            width: 100%;
            display: flex;
            justify-content: center;
            @media all and(max-width: 550px) {
                margin-top: 0;
            }
            label{
                display: block;
                width: 100%;
            }
            input{
                width: 100%;
                height: 56px;
                padding: 9px 20px;
                border-radius: 15px;
                border: solid 1px #ffc900;
                background-color: transparent;
                box-sizing: border-box;
                font-size: 18px;
                line-height: 1.33;
                color: #fff;
                @media all and(max-width: 550px) {
                    font-size: 16px;
                }
                &::placeholder {
                    color: #fff;
                    font-size: 18px;
                    @media all and(max-width: 550px) {
                        font-size: 16px;
                    }
                }
                &:focus{
                    outline: none;
                }
            }
            button {
                width: 56px;
                height: 56px;
                min-width: 56px;
                margin-left: 20px;
                box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
                display: flex;
                border-radius: 50%;
                background: @yellowColor url(../assets/images/search-black.svg) center center no-repeat;
                background-size: 16px 16px;
            }
        }
    }
</style>
