import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Loader from '@/components/app/Loader.vue';
import 'animate.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueFullPage from 'vue-fullpage.js';
import NProgress from 'vue-nprogress';
import {VueReCaptcha} from 'vue-recaptcha-v3';
import VCalendar from "v-calendar";
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification';
import moment from 'moment';
import VueMoment from 'vue-moment';
import VueCollapse from 'vue2-collapse';
import "vue-select/dist/vue-select.css";
Vue.use(VueMoment, {moment});
Vue.use(VueCollapse);
Vue.config.productionTip = false;

var VueTouch = require('vue-touch')
Vue.use(VueTouch, {name: 'v-touch'})

Vue.use(NProgress, {
    latencyThreshold: 100, // Number of ms before progressbar starts showing, default: 100,
    router: true, // Show progressbar when navigating routes, default: true
});

Vue.use(VueClipboard);
Vue.use(VueFullPage);
Vue.use(Notifications);
Vue.use(VueReCaptcha, {siteKey: process.env.VUE_APP_CAPTCHA_KEY});
Vue.component('Loader', Loader);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        v: '3.26',
        language: process.env.VUE_APP_LANGUAGE,
    },
    installComponents: true,
});

Vue.use(VCalendar);

new Vue({
    nprogress: new NProgress,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
