<template class="not-found">
    <div class="not-found_wrapper">
        <div class="not-found_img">
            <img src="../assets/images/404-img.svg" alt="404">
        </div>
        <div class="not-found_content">
            <router-link to="/" class="logo">
                <img src="../assets/images/logo.svg" alt="logo" />
            </router-link>

            <div class="not-found_info">
                <div class="not-found_title">404</div>
                <p class="not-found_description">
                    Нажаль, цієї сторінки не існує. <br>
                    Можливо, ви скористались недійсним посиланням, або сторінку було <br>
                    видалено.
                </p>
                <p class="not-found_action">Перейдіть на <router-link to="/" class="link">Головну</router-link>, або скористайтесь пошуком.</p>
                <form class="search-form" @submit.prevent="submit">
                    <input
                        type="text"
                        placeholder="Пошук"
                        autocomplete="off"
                        v-model="search"
                    >
                    <button class="search-btn">
                        <img src="../assets/images/search.svg" alt="search">
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',
        data() {
            return {
                search: ''
            }
        },
        created() {
            document.title = 'Нажаль, цієї сторінки не існує.'
        },
        methods: {
            submit() {
                this.$router.push({ name: 'search', query: { search: this.search } });
            },
        }
    }
</script>

<style lang="less" scoped>
    .not-found {
        &_wrapper {
            display: flex;
        }
        &_img {
            background-color: #fff;
            width: 30%;
            // padding-top: 259px;
            padding-top: 15%;
            img {
                width: 100%;
            }
        }
        &_content {
            max-width: 70%;
            width: 70%;
            height: 100vh;
            background: url(../assets/images/404.jpg) no-repeat center center;
            background-size: cover;
            padding: 80px;
        }

        &_title {
            font-weight: bold;
            font-size: 320px;
            line-height: 320px;
            letter-spacing: -2.24px;
            color: #fff;
            margin-bottom: 20px;
        }
        &_description {
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.168px;
            color: #FFFFFF;
            max-width: 880px;
            margin-bottom: 80px;
        }
        &_action {
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.168px;
            color: #fff;
            margin-bottom: 40px;
            .link {
                color: #5082F3;
            }
        }
    }
    .logo {
        display: inline-block;
       /* margin-bottom: 168px; */
    }
    .search-form {
        display: flex;
        input {
            width: 504px;
            height: 56px;
            border: 1px solid #FFC900;
            border-radius: 15px;
            padding-left: 20px;
            background: transparent;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            outline: none;
            margin-right: 20px;
            &::placeholder {
                color: #fff;
            }
        }
    }
    .search-btn {
        width: 56px;
        height: 56px;
        background: #FFC900;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .not-found_info {
        margin-top: auto;
    }

    @media screen and (max-width: 1920px)  {
        .not-found_img {
            max-width: 640px;
            width: 30%;
        }
        .not-found_content {
            width: 70%;
            display: flex;
            flex-direction: column;
        }
    }

     @media screen and (max-width: 1440px) {
             .not-found_title {
                 font-size: 180px;
                 line-height: 180px;
             }
             .logo {
                 margin-bottom: 100px;
             }
             .not-found_img {
                padding-top: 10%;
            }
            .not-found_content {
                padding: 80px 40px 40px;
            }
     }
      @media screen and (max-height: 800px) {
            .not-found_title {
                 font-size: 180px;
                 line-height: 180px;
             }
            .logo {
                 margin-bottom: 50px;
            }
            .not-found_img {
                padding-top: 10%;
            }
            .not-found_content {
                overflow-y: auto;
            }
            .not-found_description,
            .not-found_action {
                font-size: 20px;
                margin-bottom: 40px;
            }
      }

       @media screen and (max-height: 700px) {
            .not-found_title {
                font-size: 100px;
                line-height: 100px;
            }
            .logo {
                margin-bottom: 70px;
            }
            .not-found_description {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 50px;
            }
            .not-found_action {
                font-size: 20px;
                line-height: 30px;
            }
       }

      @media screen and (max-width: 1200px) {
            .not-found_content {
                display: block;
            }
      }
      @media screen and (max-width: 992px) {
            .not-found_content {
                max-width: 100%;
                width: 100%;
                padding: 20px;
                overflow-y: auto;
            }
            .not-found_img {
               display: none;
            }
            .search-form input {
                max-width: 504px;
                width: 80%;
            }
            .logo {
                height: 40px;
                width: 62px;
                overflow: hidden;
                img {
                    height: 100%;
                    width: auto;
                }
            }
       }

        @media screen and (max-width: 480px) {
            .not-found_wrapper {
                flex-wrap: wrap;
            }
            .not-found_title {
                font-size: 120px;
                line-height: 120px;
                letter-spacing: -0.84px;
                margin-bottom: 10px;
            }
            .not-found_description {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.126px;
                margin-bottom: 20px;
            }
            .not-found_action {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.112px;
            }
            .search-form input {
                max-width: 254px;
                width: 70%;
                height: 46px;
            }
            .search-btn {
                width: 46px;
                height: 46px;
            }
            .logo {
                margin-bottom: 140px;
            }
        }

        @media screen and (max-width: 360px) {
            .logo {
                margin-bottom: 45px;
            }
        }
</style>
