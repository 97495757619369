import Vue from "vue";
import Vuex from "vuex";
import identity from "./identity";
import settings from "./settings";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        auth: identity,
        settings: settings
    }
});
