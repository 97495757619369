export default {
    state: () => ({
        showNotFound: false,
    }),
    actions: {
        setShowNotFound({commit, state}, show) {
            commit('setShowNotFound', show);
        }
    },
    mutations: {
        setShowNotFound(state, show) {
            state.showNotFound = show;
        }
    },
    getters: {
        showNotFound: state => {
            return state.showNotFound;
        }
    }
}
