<template>
    <button class="custom-button" @click="clickHandler" :class="`${theme}-theme-button`">{{label}}</button>
</template>

<script>
export default {
    name: "CustomButton",
    props: {
        label: {
            type: String,
            required: true
        },
        theme: {
            type: String,
            required: false,
            default: 'light'
        }
    },
    methods: {
        clickHandler(e) {
            this.$emit('click', e);
        }
    }
}
</script>

<style lang="less">
.custom-button {
    background-color: @yellowColor;
    width: 220px;
    height: 50px;
    position: relative;
    font: 600 18px/1.33 @secondFontFamily!important;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 24px 24px -14px fade(@yellowColor, 35%);
    transition: box-shadow .15s linear, background-color .15s linear, color .15s linear;
    border: 1px solid @yellowColor;
    &.dark-theme-button {
        @greyColor: #cdd4e0;
        box-shadow: none;
        background-color: @greyColor;
        border-color: @greyColor;
        &:hover{
            background-color: fade(@greyColor, 60%);
        }
    }
    &.blue-theme-button {
        box-shadow: none;
        background-color: @blueColor;
        border-color: @blueColor;
        &:hover{
            background-color: fade(@blueColor, 60%);
        }
    }
    &:hover{
        background-color: fade(@yellowColor, 60%);
    }
    &:active{
        box-shadow: none;
    }
}
</style>
