<template>
    <li>
        <router-link :to="link" v-if="link">
            {{ label }}
            <span>Відвідати</span>
        </router-link>
        <a v-else>
            {{ label }}
            <span>Відвідати</span>
        </a>
    </li>
</template>

<script>
export default {
    name: "Item",
    props: ['label', 'link']
}
</script>

<style scoped lang="less">
@background: #ffc900;
a{
    color: #fff;
    text-decoration: none;
    font-family: inherit;
}
@media all and (min-width: 768px) {
    li {
        flex: 1 1 auto;
        //max-height: 25%;
        max-height: none;
        width: 100%;
        a {
            display: flex;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: -0.17px;
            justify-content: center;
            padding: 100px 80px;
            box-sizing: border-box;
            height: 100%;
            line-height: 1.6;
            flex-direction: column;
            transition: background-color .2s linear, color .15s linear;

            &:hover {
                background-color: @background;
                color: #1a2744;

                span {
                    color: #fff;
                }
            }
            span {
                padding-top: 5px;
                color: @background;
                font-size: 20px;
                font-weight: bold;
                transition: color .15s linear;
            }
        }
    }
}
@media all and (max-width: 1280px) {
    li {
        a {
            padding: 100px 20px;
        }
    }
}
@media all and (max-width: 768px) {
    li{
        margin: 10px 0;
        padding: 0 20px;
        a{
            padding: 20px 0;
            transform: rotate(-360deg);
            font-size: 24px;
            display: block;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.17px;
            text-align: center;
            color: #fff;
            span{
                display: none;
            }
        }
    }
}
</style>
