<template>
    <div class="admin-bar">
        <div class="admin-side">
            <a v-if="visibleAdmin" href="/admin">Адмін панель</a>
        </div>
        <div class="panel-action">
            <button class="search-btn" @click="visibleSearch = !visibleSearch" :class="{active: visibleSearch}">Пошук</button>
            <router-link to="/profile" class="to-cabinet">Мій кабінет</router-link>
        </div>
        <SearchModal :visible="visibleSearch" @close="visibleSearch = !visibleSearch"/>
    </div>
</template>
<script>
import SearchModal from "@/components/SearchModal";

export default {
    name: 'AuthNav',
    components: {
        SearchModal
    },
    data() {
        return {
            visibleSearch: false,
            visibleAdmin: this.$store.getters.identity.visible_admin
        }
    }
}
</script>
<style lang="less">
.admin-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 36px;
    padding: 0 20px 0 20px;
    width: 100%;
    background: #1a2744;
    box-shadow: 0 0 10px #000;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a.to-cabinet {
        background-color: #ffc900;
        border-radius: 10px;
        box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
        font-size: 15px;
        font-weight: 600;
        color: #1a2744;
        margin: 5px 0;
        display: flex;
        align-items: center;
        align-content: center;
        padding: 1px 20px 0;
        text-decoration: none;
        font-family: inherit;
        transition: background-color .1s linear;
        &:before {
            content: '';
        }
        &:hover {
            background-color: #fdd231;
        }
    }

    a, button {
        font-size: 13px;
        color: #ffc900;
        display: flex;
        align-items: center;
        align-content: center;
        padding: 0 20px;
        text-decoration: none;
        font-family: inherit;
        transition: background-color .1s linear;
        &:before {
            content: '';
        }
        &:hover {
            background-color: rgba(0, 0, 0, .2);
        }
    }
    .panel-action {
        a, button {
            &:before {
                width: 16px;
                margin-right: 13px;
                height: 16px;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    .admin-side, .panel-action {
        display: flex;
        height: 100%;
    }
    .search-btn {
        &.active{
            background-color: rgba(0, 0, 0, .2);
        }
        &:before {
            background-image: url(../assets/images/search-ic.svg);
        }
    }
    .to-cabinet {
        &:before {
            background-image: url(../assets/images/user-ic.svg);
        }
    }
}

@media screen and (max-width: 992px) {
    .admin-bar {
       display: none;
    }
}

</style>
