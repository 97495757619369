<template>
    <div :class="{'yellow-participant' : !deputies}">
        <button
            class="btn-menu"
            :class="{ hidden: visibleMenu, inverse: isMember }"
            @click="visibleMenu = true"
        >
            <span></span>
        </button>
        <transition
            enter-active-class="animate__animated animate__fadeInLeft"
            leave-active-class="animate__animated animate__fadeOutLeft"
            mode="out-in"
        >
            <div class="menu-page" v-if="visibleMenu" @wheel.stop>
                <button class="close-menu" @click="closeMenu"></button>
                <div class="main-nav"
                         :class="{ opened: currentSlide.sub && currentSlide.sub.length && t }"
                >

                    <div class="main-nav__col main-nav__control">
                        <transition
                            enter-active-class="animate__animated animate__fadeInLeft"
                            leave-active-class="animate__animated animate__fadeOutLeft"
                            mode="out-in"
                        >
                            <div class="container" v-if="previsionSlide.id">
                                <button
                                    class="yellow-arrow yellow-arrow--prev"
                                    @click="slideHandler(previsionSlide.id)"
                                    :class="{'yellow-arrow--dark' : deputies}"
                                >
                                    <img src="@/assets/images/arrow_menu.svg" alt="svg">
                                </button>
                                <transition
                                    enter-active-class="animate__animated animate__backInDown"
                                    leave-active-class="animate__animated animate__fadeOutLeft"
                                    mode="out-in">
                                    <span
                                        v-for="item in availableItems"
                                        :key="item.id"
                                        v-if="item.id === previsionSlide.id && !visibilitySubmenu"
                                        v-html="item.name"
                                    ></span>
                                </transition>
                            </div>
                        </transition>
                    </div>
                    <div class="main-nav__col main-nav__item-bg">
                        <div class="main-nav__item" :class="{'main-nav__item--dark' : deputies}">
                            <div class="main-nav__item-container">
                                <div class="bg-logo">
                                    <div class="logo">
                                        <svg width="481px" height="337px" viewBox="0 0 481 337">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g v-if="deputies" fill="#1a2744" fill-rule="nonzero">
                                                    <path d="M481,0 L481,337 L-0.00135192869,337 L-0.00135192869,303.325388 C26.1539063,323.916336 59.5641389,335.595525 97.5960344,335.595525 C143.900527,335.595525 182.151763,321.283192 210.502793,294.092758 C188.353227,273.58239 172.347017,246.463807 164.524803,214.568866 C147.781889,236.378833 127.911611,248.291477 102.63468,248.291477 C75.737691,248.291477 55.9863925,233.20343 46.3241456,208.065987 L126.546805,208.065987 L126.546805,130.355813 L45.902875,130.355813 L46.1834192,129.563862 C55.5452094,103.538314 75.1780236,88.3047452 102.63468,88.3047452 C127.911611,88.3047452 147.781889,100.909393 164.531029,122.718663 C172.347017,90.824419 188.353227,63.7121147 210.502793,43.2010488 C182.151763,16.0050344 143.900527,1 97.5960344,1 C59.5641389,1 26.1539063,12.6794254 -0.00135192869,33.270214 L-0.00135192869,0 L481,0 Z M348.137764,1 C253.650849,1 184.357542,69.8747838 184.357542,167.943438 C184.357542,266.024621 253.650849,335.595525 348.137764,335.595525 C405.670322,335.595525 451.022547,312.786465 480,271.737676 L480,271.737676 L417.425541,211.062487 L416.90803,211.770999 C399.796782,235.052384 379.370911,248.468481 353.178974,248.468481 C312.020498,248.468481 284.30594,216.756746 284.30594,167.943438 C284.30594,119.142659 312.020498,88.1270436 353.178974,88.1270436 C379.635476,88.1270436 400.208838,101.809973 417.425541,125.521204 L417.425541,125.521204 L480,64.8641139 L479.125768,63.6379406 C450.114285,23.3551593 405.094996,1 348.137764,1 Z"></path>
                                                </g>
                                                <g v-else fill="#FFC900" fill-rule="nonzero">
                                                    <path d="M481,0 L481,337 L-0.00135192869,337 L-0.00135192869,303.325388 C26.1539063,323.916336 59.5641389,335.595525 97.5960344,335.595525 C143.900527,335.595525 182.151763,321.283192 210.502793,294.092758 C188.353227,273.58239 172.347017,246.463807 164.524803,214.568866 C147.781889,236.378833 127.911611,248.291477 102.63468,248.291477 C75.737691,248.291477 55.9863925,233.20343 46.3241456,208.065987 L126.546805,208.065987 L126.546805,130.355813 L45.902875,130.355813 L46.1834192,129.563862 C55.5452094,103.538314 75.1780236,88.3047452 102.63468,88.3047452 C127.911611,88.3047452 147.781889,100.909393 164.531029,122.718663 C172.347017,90.824419 188.353227,63.7121147 210.502793,43.2010488 C182.151763,16.0050344 143.900527,1 97.5960344,1 C59.5641389,1 26.1539063,12.6794254 -0.00135192869,33.270214 L-0.00135192869,0 L481,0 Z M348.137764,1 C253.650849,1 184.357542,69.8747838 184.357542,167.943438 C184.357542,266.024621 253.650849,335.595525 348.137764,335.595525 C405.670322,335.595525 451.022547,312.786465 480,271.737676 L480,271.737676 L417.425541,211.062487 L416.90803,211.770999 C399.796782,235.052384 379.370911,248.468481 353.178974,248.468481 C312.020498,248.468481 284.30594,216.756746 284.30594,167.943438 C284.30594,119.142659 312.020498,88.1270436 353.178974,88.1270436 C379.635476,88.1270436 400.208838,101.809973 417.425541,125.521204 L417.425541,125.521204 L480,64.8641139 L479.125768,63.6379406 C450.114285,23.3551593 405.094996,1 348.137764,1 Z"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div class="body">
                                    <transition
                                        enter-active-class="animate__animated animate__fadeIn"
                                        leave-active-class="animate__animated animate__fadeOut"
                                        mode="out-in">
                                        <header
                                            v-for="item in availableItems"
                                            :key="item.id"
                                            v-if="item.id === currentSlide.id"
                                            v-html="item.name"
                                        ></header>
                                    </transition>
                                    <transition
                                        enter-active-class="animate__animated animate__fadeIn"
                                        leave-active-class="animate__animated animate__fadeOut"
                                        mode="out-in"
                                    >
                                        <p
                                            v-for="item in items"
                                            :key="item.id"
                                            v-if="item.id === currentSlide.id"
                                            :style="{ 'opacity: 0;': item.test }"
                                        >
                                            {{ item.description }}
                                        </p>
                                    </transition>
                                    <footer>
                                        <button class="back-to-main" @click="closeSubMenu">
                                            <svg width="16" height="25" viewBox="0 0 16 25" fill="none">
                                                <path
                                                    d="M5.08061 12.5401C5.08061 12.5401 13.8018 4.2207 14.4966 3.55797C15.1913 2.89525 15.295 1.9197 14.4966 1.15802C13.6981 0.396349 12.6754 0.495296 11.9807 1.15802C11.286 1.82075 1.30682 11.3401 1.30682 11.3401C0.612083 12.0029 0.612083 13.0774 1.30682 13.7401L11.9807 23.9222C12.6754 24.5849 13.8018 24.5849 14.4966 23.9222C15.1913 23.2595 15.1913 22.185 14.4966 21.5223L5.08061 12.5401Z"
                                                />
                                            </svg>
                                        </button>
                                        <CustomButton
                                            label="Відвідати"
                                            @click="clickHandler"
                                            :theme="btn_theme"
                                        />
                                    </footer>
                                </div>

                                <v-touch
                                    class="touch-container"
                                    v-on:swiperight="onSwipeRight(previsionSlide.id)"
                                    v-on:swipeleft="onSwipeLeft(nextSlide.id)">
                                </v-touch>
                            </div>
                            <SubNav
                                v-if="currentSlide.sub && currentSlide.sub.length"
                                :class="{ visibility: visibilitySubmenu }"
                                :type="currentSlide.type"
                                :mobileHeader="currentSlide.name.replace(/(<([^>]+)>)/ig, '')"
                                :list="currentSlide.sub"
                            />
                        </div>
                    </div>
                    <div class="main-nav__col main-nav__control">
                        <transition
                            enter-active-class="animate__animated animate__fadeInRight"
                            leave-active-class="animate__animated animate__fadeOutRight"
                            mode="out-in"
                        >
                            <div class="container" v-if="nextSlide.id">
                                <button
                                    class="yellow-arrow yellow-arrow--next"
                                    @click="slideHandler(nextSlide.id)"
                                    :class="{'yellow-arrow--dark' : deputies}"
                                >
                                    <img src="@/assets/images/arrow_menu.svg" alt="svg">
                                </button>
                                <transition
                                    enter-active-class="animate__animated animate__fadeInRight"
                                    leave-active-class="animate__animated animate__fadeOutRight"
                                    mode="out-in"
                                >
                                    <span
                                        v-for="item in availableItems"
                                        :key="item.id"
                                        v-if="item.id === nextSlide.id"
                                        v-html="item.name"
                                    ></span>
                                </transition>
                            </div>
                        </transition>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import CustomButton from "@/components/CustomButton";
import SubNav from "@/components/subMainNav/SubNav";
import axios from "axios";

export default {
    components: {CustomButton, SubNav},
    name: "Menu",
    data() {
        return {
            t: false,
            visibilitySubmenu: false,
            visibleMenu: false,
            currentSlideId: 1,
            deputies: this.$store.getters.identity.is_deputy,
        };
    },
    methods: {
        closeMenu() {
            if(this.visibilitySubmenu && window.innerWidth <= 768) {
                this.visibilitySubmenu = false;
                this.t = false;
                return false;
            }

            this.visibleMenu = false;
        },
        slideHandler(nextSlideId) {
            this.t = false;
            this.visibilitySubmenu = false;

            this.currentSlideId = nextSlideId;
        },

        async clickHandler() {
            if(this.currentSlide.type) {
                let res = await axios.get('/api/menu/sub-menu', {params: {type: this.currentSlide.type}});
                if(res.data.length) {
                    this.currentSlide.sub = res.data.map(item => {
                        item.name = item.menu_name || item.title;
                        if(!item.url) {
                            item.link = '/' + this.currentSlide.type + '/' + item.id;
                        }else{
                            item.link = item.url;
                        }

                        return item;
                    });
                }
            }
            if (!this.currentSlide.sub) {
                if (this.currentSlide.link) {
                    this.$router.push(this.currentSlide.link).catch(()=>{});
                    this.closeMenu();
                }
                return;
            }

            if (this.t) {
                if (this.currentSlide.link) {
                    this.$router.push(this.currentSlide.link).catch(()=>{});
                    this.closeMenu();
                } else {
                    location.href = "/study";
                }
            } else {
                this.t = !this.t;
                setTimeout(() => (this.visibilitySubmenu = true), 400);
            }
        },
        closeSubMenu() {
            this.visibilitySubmenu = false;
            if (this.currentSlide.sub) {
                setTimeout(() => (this.t = !this.t), 400);
            } else {
                this.t = !this.t;
            }
        },
        keyboardListener(e) {
            if (e.keyCode !== 27) {
                return;
            }

            if (this.visibilitySubmenu) {
                this.closeSubMenu();
            } else {
                this.visibleMenu = false;
            }
        },
        onSwipeLeft(id) {
            if (id) {
                this.slideHandler(id)
            }
        },
        onSwipeRight(id) {
            if (id) {
                this.slideHandler(id)
            }
        },
    },
    watch: {
        visibleMenu() {
            if (this.visibleMenu) {
                window.addEventListener("keyup", this.keyboardListener, false);
            } else {
                //this.currentSlideId = 1;
                window.removeEventListener("keyup", this.keyboardListener, false);
            }
        },
        $route() {
            this.visibleMenu = false;
            this.visibilitySubmenu = false;
            setTimeout(() => {
                this.t = false;
                //this.currentSlideId = 1;
            });
        }
    },
    computed: {
        btn_theme() {
            return !this.deputies ? "blue" : "";
        },
        currentSlide() {
            return this.availableItems.find((item) => item.id === this.currentSlideId);
        },
        previsionSlide() {
            for(let i = 0; i < this.availableItems.length; i++){
                let item = this.availableItems[i];

                if(item.id === this.currentSlideId && i) {
                    return this.availableItems[i - 1];
                }
            }

            return {}
        },
        nextSlide() {
            let nextSlideId = this.currentSlideId;
            let c = false;
            let item = this.availableItems.find((item) => {
                if(item.id === nextSlideId) {
                    c = true
                }else if(c) {
                    return true;
                }
            });
            return item ? item : {};
        },
        isMember() {
            return this.$route.name === 'member'
        },
        items() {
            let items = [
                {
                    id: 1,
                    name: "Позиція партії",
                    type: 'position',
                    description: "Тут можете знайти заяви партії та інформацію щодо найактуальніших питань української політики. Оцінку дій влади, основні реформи та хід подій політичних процесів. Інформація постійно оновлюється та доповнюється. Ваша думка важлива, тому заходьте і активно голосуйте.",
                    link: "/position",
                }, {
                    id: 3,
                    name: "Навчання<br/> та можливості",
                    link: "/study",
                    type: 'study',
                    description:
                        "Користуйтесь навчальними посібники та корисними матеріалами, соціологічними дослідженнями від міжнародних партнерів включно з семінарами та лекціями від лідерів та експертів партії. А також додатковими можливостями для саморозвитку.",
                }, {
                    id: 4,
                    name: "Календар<br/> подій",
                    description: "Маєте успішний кейс чи ідею, якою хотіли б поділитися? Створюйте власні ініціативи та надихайтеся ідеями та пропозиціями однопартійців по всій країні. Долучайтеся, запрошуйте друзів і допомагайте поширювати наші спільні ідеї та цінності.",
                    link: "/events",
                }, {
                    id: 5,
                    name: "Картки депутатів",
                    description: "Для полегшення комунікації та покращення ефективності роботи депутатів тут можна знайти контактну інформацію про своїх колег-депутатів місцевих рад усіх рівнів.",
                    link: '/cards'
                },
                {
                    id: 6,
                    name: "Картки партійця",
                    description: "Для полегшення комунікації та покращення ефективності роботи партійців тут можна знайти контактну інформацію про своїх колег-партійців.",
                    link: '/cards-participant'
                },

            ];

            if(this.deputies) {
                items.splice(1, 0, {
                    id: 2,
                    name: "Нормативна <br/> база",
                    description: "База документів, шаблонів заяв та звернень, законодавчих актів необхідних для діяльності депутатів місцевих рад усіх рівнів.",
                    link: "/base",
                });

                items.push({
                    id: 7,
                    name: "Рейтинги",
                    description: "Рейтинги місцевих депутатів з найкращими показниками медійної та партійної діяльності. Ефіри, виступи, ініціативи, реалізовані проєкти та заходи формують перелік найактивніших місцевих депутатів «Європейської солідарності».",
                    link: "/ratings",
                });

                items.push({
                    id: 8,
                    name: "Зворотний зв'язок",
                    description: "Маєте питання, на яке не знаєте відповідь? Залиште тут запит. Фахові партійні експерти допоможуть. Також за запитом можна отримати юридичну консультацію.",
                    link: "/feedback",
                });
            }

            return items;
        },

    availableItems() {
            let items = this.items;
            if(!this.deputies) {
                items = items.filter(item => ![2,7,8, 5].includes(item.id));
            }
            if(this.deputies) {
                items = items.filter(item => ![6].includes(item.id));
            }

            return items;
        }
    },
};
</script>

<style lang="less">
.menu-page .custom-button{
    position: relative;
    z-index: 3;

    @media all and (max-width: 768px) {
        width: 180px;
        height: 46px;
        font-size: 16px!important;
        font-weight: bold;
        line-height: 1.25;
        svg{
            transform: scaleX(1.2);
        }
        svg > g {
            fill: @yellowColor;
        }
        &:after{
            width: 155px;
            margin-left: -79px;
            box-shadow: 0 24px 24px -14px rgb(249 198 35 / 35%);
            top: -20%;
        }
        span{
            font-size: 16px;
            font-weight: bold;
            line-height: 1.25;
        }
    }
}
</style>

<style scoped lang="less">
.close-menu {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 35px;
    height: 35px;
    z-index: 3;
    transition: transform 0.2s linear;
    @media all and (max-width: 768px) {
        width: 16px;
        height: 16px;
        top: 35px;
        right: 25px;
    }
    &:before, &:after {
        content: "";
        height: 7px;
        width: 42px;
        display: block;
        border-radius: 3.5px;
        background-color: #fff;
        @media all and (max-width: 768px) {
            width: 21px;
            height: 3.5px;
        }
    }
    &:hover {
        transform: rotate(90deg);
    }
    &:before {
        transform: rotate(-225deg) translateX(5px);
        @media all and (max-width: 768px) {
            transform: rotate(-225deg) translateX(2px);
        }
    }
    &:after {
        transform: rotate(225deg) translateX(5px);
        @media all and (max-width: 768px) {
            transform: rotate(225deg) translateX(2px);
        }
    }
}

.touch-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}
.btn-menu {
    position: fixed;
    top: 30px;
    left: -45px;
    z-index: 100;
    width: 45px;
    height: 154px;
    background: transparent url("../assets/images/arrow.svg") 5px center no-repeat;
    transform: rotate(180deg);
    transition: transform 0.15s linear;
    transform-origin: right center;
    &.hidden {
        transform: translateX(-45px)!important;
    }
    &:before,
    &:after,
    span {
        content: "";
        display: block;
        width: 18px;
        height: 4px;
        border-radius: 3px;
        background-color: #fff;
        margin: 0 auto 3px auto;
        position: relative;
        left: 4px;
        transition: opacity 0.15s linear, transform 0.15s linear;
    }

    @media (max-width: 1100px) {
        top: 10px;
    }
    @media all and(max-width: 950px) {
        width: 20px;
        height: 82px;
        left: -20px;
        background-size: 180% auto;
        background-position: 0;
        top: -2px;
        &:before,
        &:after,
        span{
            width: 10px;
            height: 2px;
            margin-bottom: 2px;
            left: 1px;
        }
    }

    &:before,
    &:after {
        transform-origin: top right;
    }

    &:hover {
        transform: rotate(180deg);
    }

    &.inverse {
        background: transparent url("../assets/images/arrow-b.svg") 5px center no-repeat;
    }
}
.yellow-participant{
    .btn-menu{
        background: transparent url(../assets/images/right.svg) 5px center no-repeat;
        left: -2px;
        transform: rotate(0deg) scale(1.05);
        background-position: center;
        background-size: contain;

        &:before, &:after, span {
            left: 0;
        }

        @media screen and (max-width: 992px) {
            left: 1px;
        }
    }
}
.menu-page {
    min-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: transparent url("../assets/images/kiev_city.jpg") center center no-repeat;
    background-size: cover;
    min-width: 320px;
    @media all and (max-width: 768px) {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.main-nav {
    @background: #ffc900;
    position: relative;
    z-index: 2;
    height: 100vh;
    display: flex;
    transition: opacity .2s linear, visibility .2s linear;
    .back-to-main {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: @background;
        float: left;
        margin-right: 10px;
        opacity: 0;
        visibility: hidden;
        margin-left: -60px;
        transition: opacity 0.15s linear, margin-left 0.15s linear;
        @media all and (max-width: 1280px) {
            // width: 52px;
            // height: 52px;
        }
        @media all and (max-width: 768px) {
            //display: none;
        }
        svg {
            width: 20px !important;
            height: 40px !important;
            position: relative;
            left: -2px;
            fill: #1a2744;
        }
    }
    .slider-control {
        color: #fff;
        position: absolute;
        top: 15vh;
        left: 70%;
        @media all and (max-width: 1290px) {
            top: 109px;
            left: 55%;
        }
        @media all and (max-width: 768px) {
            left: 63%;
            top: 133px;
        }
        @media all and (max-width: 480px) {
            top: 93px;
        }
        li {
            display: inline-block;
            margin: 0 3px;
            position: absolute;
            left: 0;
            transition: transform 0.2s linear, opacity 0.2s linear;
            z-index: 1;
            &.hidden {
                opacity: 0;
                z-index: 0;
            }
            button {
                color: #fff;
                font-weight: 600;
                letter-spacing: -0.28px;
                font-size: 40px;
                opacity: 0.8;
                transform: scale(.5);
                transition: opacity 0.15s linear, color 0.15s linear, transform 0.2s linear;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                    letter-spacing: -0.17px;
                    transform: scale(.55);
                }
            }
            &.current-slide {
                transform: translateX(55px);
                button {
                    opacity: 1;
                    transform: scale(1);
                    cursor: default;
                }
                & + li {
                    transform: translateX(110px);
                }
                @media all and (max-width: 768px) {
                    transform: translateX(40px);
                    & + li {
                        transform: translateX(80px);
                    }
                }
            }
        }
    }
    &__col {
        box-sizing: border-box;
        flex: 1 1 auto;
        max-width: 33.333%;
        @media all and (max-width: 768px) {
            max-width: 100%;
        }
    }
    &__item-bg {
        width: 100%;
        display: flex;
        align-items: center;
    }
    &.opened {
        .back-to-main {
            margin-left: 0;
            opacity: 1;
            visibility: visible;
            z-index: 100;
            background-color: @blueColor;

            @media all and (max-width: 768px) {
                display: none;
            }
        }
        .main-nav__item--dark {
            .back-to-main {
                background-color: @yellowColor;
                @media all and (max-width: 768px) {
                    display: none;
                }
            }
        }

        .yellow-arrow:not(.yellow-arrow--dark) {
            filter: hue-rotate(-11deg) brightness(0.9);
        }

        .slider-control {
            button {
                opacity: 0.4;
            }
            li.current-slide {
                button {
                    color: @background;
                }
            }
        }
        .main-nav__item {
            left: 320px;
            @media all and (max-width: 1290px) {
                left: 250px;
            }
            @media all and (max-width: 768px) {
                left: 0;
            }
            &-container {
                scrollbar-color: @yellowColor @blueColor;
                @media all and (max-width: 768px) {
                     opacity: 0;
                     visibility: hidden;
                }
                &::-webkit-scrollbar-track {
                    background: @blueColor;
                }
                &::-webkit-scrollbar-thumb {
                    background: @yellowColor;
                }
            }
            
        }
    }
    .back-btn {
        svg {
            path {
                fill: #fff !important;
            }
        }
    }
    &__item {
        position: absolute;
        height: 100%;
        max-width: 640px;
        width: 100%;
        left: 50%;
        margin-left: -320px;
        transition: all 0.4s ease-out;
        @media all and (max-width: 1290px) {
            max-width: 500px;
            margin-left: -250px;
        }
        @media all and (max-width: 768px) {
            max-width: 100%;
            position: absolute;
            letter-spacing: 0;
            margin-left: 0;
            left: 0;
            padding: 0 40px;
            svg{
                display: none;
            }
        }
        @media all and (max-width: 480px) {

        }
        &-container {
            position: relative;
            z-index: 2;
            height: 100vh;
            display: flex;
            flex-direction: column;
            scrollbar-color: @blueColor @yellowColor;
            scrollbar-width: thin;
            transition: opacity .4s linear, visibility .4s linear;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 2px;
            }
            &::-webkit-scrollbar-track {
                background: @yellowColor;
            }
            &::-webkit-scrollbar-thumb {
                background: @blueColor;
                border-radius: 0;
            }
            @media all and (max-width: 768px) {
                display: block;
                padding-top: 100px;
            }

            @media all and (max-width: 480px) {
                padding-top: 60px;
                overflow-x: hidden;
            }
        }
        svg {
            margin: -1px 0;
            width: initial;
            height: 25vh;

            path {
                transition: fill 0.4s ease-out;
                fill: @yellowColor;
            }
            @media all and (max-width: 1290px) {
                width: 300px;
                height: auto;
            }
        }
        .logo {
            font-size: 0;
            border-color: @yellowColor;
            flex: 0 0 auto;
            transition: border-color 0.4s ease-out;
        }
        .bg-logo{
            display: flex;
            border-top: 40px solid @yellowColor;
            transition: border-color 0.4s ease-out;

            @media all and (max-width: 1290px) {
                border-top-width: 30px;
            }
            @media all and (max-width: 768px) {
                border: 0;
                width: 160px;
                height: 100px;
                background: transparent url(../assets/images/logo.svg) left center no-repeat;
                background-size: auto 100%;
                margin-bottom: 100px;
            }

            @media all and (max-width: 480px) {
                margin-bottom: 70px;
            }

            &:after{
                content: '';
                display: block;
                background-color: @yellowColor;
                height: inherit;
                width: 100%;
                margin: -1px 0;
                transition: background-color 0.4s ease-out;
                @media all and (max-width: 768px) {
                    display: none;
                }
            }
            .logo{
                flex: 1 1 auto;
                margin-right: -2px;
            }
        }
        .body {
            flex: 1 1 auto;
            background-color: @yellowColor;
            box-sizing: border-box;
            padding: 32px 60px 50px;
            color: @blueColor;
            display: flex;
            flex-direction: column;
            transition: background-color 0.4s ease-out;
            @media all and (max-width: 1290px) {
                padding-left: 40px;
                padding-right: 40px;
            }
            @media all and (max-width: 768px) {
                background-color: transparent;
                padding: 0;
            }
            header, p{
                --animate-duration: .4s;
            }
            header {
                font: 700 60px/1.13 @secondFontFamily;
                letter-spacing: -0.42px;
                margin-bottom: 35px;
                transition: color 0.4s ease-out;
                @media all and (max-width: 1290px) {
                    font-size: 52px;
                    margin-bottom: 20px;
                }
                @media all and (max-width: 768px) {
                    font-size: 30px;
                    line-height: 1.33;
                    letter-spacing: -.21px;
                    color: #fff;
                    margin-bottom: 20px;
                }
            }
            p {
                font-size: 20px;
                line-height: 1.5;
                letter-spacing: -0.14px;
                margin-bottom: 50px;
                transition: color 0.4s ease-out;
                @media all and (max-width: 1280px) {
                    font-size: 18px;
                }
                @media all and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 1.57;
                    letter-spacing: -.1px;
                    color: #fff;
                    margin-bottom: 35px;
                }
            }
            footer{
                display: flex;
                flex: 1 1 auto;
                align-items: flex-end;
                justify-content: flex-start;
                .custom-button{
                    max-width: 240px;
                }
            }
            @media all and (max-height: 800px) {
                padding-bottom: 20px;
                header{
                    margin-bottom: 25px;
                    line-height: 1;
                }
                p{
                    margin-bottom: 30px;
                    line-height: 1.4;
                }
            }
        }

        &--dark {
            svg {
                path {
                    fill: @blueColor;
                }

                @media all and (max-width: 1290px) {
                    width: 300px;
                    height: auto;
                }
            }

            .body {
                color: white;
                background-color: @blueColor;

                @media all and (max-width: 768px) {
                    background-color: transparent;
                    padding: 0;
                }
            }

            .bg-logo{
                border-top: 40px solid @blueColor;
                transition: border-color 0.4s ease-out;

                @media all and (max-width: 1290px) {
                    border-top-width: 30px;
                }

                @media all and (max-width: 768px) {
                    border: 0;
                    width: 160px;
                    height: 100px;
                    background: transparent url(../assets/images/logo.svg) left center no-repeat;
                    background-size: auto 100%;
                    margin-bottom: 100px;
                }

                &:after{
                    background-color: @blueColor;

                    @media all and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
    &__control {
        display: flex;
        align-items: center;
        position: relative;
        @media all and (max-width: 768px) {
            display: block;
        }
        span {
            font: 600 46px/1.4 @secondFontFamily;
            letter-spacing: -0.28px;
            color: #f5f5f5;
            @media all and (max-width: 1500px) {
                font-size: 32px;
            }
            @media all and (max-width: 1400px) {
                font-size: 27px;
            }
            @media all and (max-width: 1199px) {
                display: none!important;
            }
        }
        .container {
            width: 100%;
            padding-left: 100px;
            box-sizing: border-box;
            --animate-duration: .4s;

            @media all and (max-width: 1400px) {
                padding-left: 55px;
            }

            @media all and (max-width: 1199px) {
                height: 100%;
            }

            @media all and (max-width: 850px) and (orientation: landscape) {
                height: 100%;
            }
        }
        .yellow-arrow {
            position: absolute;
            top: 50%;
            width: 39px;
            height: 154px;
            background: transparent url("~@/assets/images/arrow.svg") 0 center no-repeat;
            background-size: auto 100%;
            transition: transform 0.15s linear;
            z-index: 100;

            &--dark {
                background: transparent url("~@/assets/images/arrow-b.svg") 0 center no-repeat;
                filter: brightness(0.7);
            }

            img {
                transform: rotate(180deg);
                left: 5px;
                position: relative;

                @media all and (max-width: 768px) {
                    left: 3px;
                    height: 14px;
                }
            }

            @media all and (max-width: 768px) {
                width: 20px;
                height: 82px;
                background-size: 180% auto;
                background-position: 0;
            }

            @media all and (max-width: 850px) and (orientation: landscape) {
                margin-top: 0;
                top: 50%;
                transform: translate(0, -50%) rotate(180deg) !important;
            }
        }
    }

    &__control:first-child {
        .yellow-arrow {
            left: 0;
            z-index: 10;
            margin-top: 0;
            top: 50%;
            transform: translate(0, -50%) rotate(180deg) !important;
        }
    }

    &__control:last-child {
        button.yellow-arrow {
            right: 0;
            top: 50%;
            margin-top: 0;
            transform: translate(0, -50%) rotate(0) !important;
        }

        @media all and (max-width: 1500px) {
            span{
                display: block;
                text-align: right;
                padding-right: 90px;
            }
        }
        @media all and (max-width: 1400px) {
            span{
                padding-right: 60px;
            }
        }
    }
}

@media all and (max-height: 730px) and (min-width: 768px){
    .main-nav__item svg {
        width: initial !important;
        height: 18vh !important;
    }
    .main-nav .slider-control {
        top: 10vh;
    }
    .main-nav__item .body header[data-v-11c1ffa5] {
        margin-bottom: 15px;
        font-size: 42px;
    }
    .main-nav__item .body p[data-v-11c1ffa5] {
        margin-bottom: 10px;
        font-size: 16px;
    }
}
</style>

