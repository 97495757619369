import router from "../router";
import store from "../store";

export default function (err) {
    err.errors = null;
    if('response' in err) {
        if(err.response != null) {
            if(err.response.status === 403) {
                window.location = '/login';
            } else if(err.response.status === 422) {
                err.errors = err.response.data;
                err.errors['statusCode'] = err.response.status;
            } else if (err.response.status >= 405 && err.response.status < 500) {
                store.dispatch('setShowNotFound', true).then();
            }
        }
    }

    throw err.errors;
}
