<template>
    <div class="sub-nav">
        <header>{{mobileHeader}}</header>
        <ul ref="sub">
            <Item v-for="item in list" :link="item.link" :key="item.id" :label="item.name"/>
        </ul>
    </div>
</template>

<script>
import Item from "@/components/subMainNav/Item";
import axios from "axios";

let resize = (function(){
    let callbacks = [],
        running = false;
    function resize() {
        if (!running) {
            running = true;
            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(runCallbacks);
            } else {
                setTimeout(runCallbacks, 66);
            }
        }
    }
    function runCallbacks() {
        callbacks.forEach(function(callback) {
            callback();
        });
        running = false;
    }
    function addCallback(callback) {
        if (callback) {
            callbacks.push(callback);
        }
    }
    return {
        add: function(callback) {
            if (!callbacks.length) {
                window.addEventListener('resize', resize);
            }
            addCallback(callback);
        }
    }
}());
export default {
    name: "SubNav",
    components: {Item},
    props: {
        type: {
            type: String,
            require: true
        },
        mobileHeader: {
            type: String,
            require: true
        },
        list: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 768;
        }
    },
    mounted() {
        this.$refs.sub.focus();
        //resize.add(() => this.windowWidth = window.innerWidth);
    }
}
</script>

<style scoped lang="less">
.sub-nav {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 640px;
    background-color: #1a274460;
    height: 100vh;
    transform: scaleX(0);
    transform-origin: left center;
    transition: visibility .4s linear, opacity .4s linear, transform .4s linear;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1280px)  {
        min-width: 450px;
    }
    @media all and (max-width: 1170px) {
        min-width: 400px;
    }
    @media all and (max-width: 768px) {
        transition: visibility .4s linear, opacity .4s linear;
    }
    header{
        display: none;
    }
    @media all and (max-width: 768px) {
        min-width: 100%;
        header{
            display: block;
            margin-bottom: 25px;
            font-size: 30px;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: -.21px;
            color: #fff;
            padding: 25px 20px;
            &:after{
                content: '';
                display: block;
                width: 100%;
                margin-top: 20px;
                border-top: 1px solid rgba(0,0,0,.15);
                border-bottom: 1px solid rgba(255,255,255,.1);
            }
        }
    }
    ul{
        display: flex;
        align-items: center;
        flex-direction: column;
        list-style: none;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 2px;
        }
        &::-webkit-scrollbar-track {
            background: #ffc900;
        }
        &::-webkit-scrollbar-thumb {
            background: #1a2744;
            border-radius: 0;
        }
    }
    &.visibility {
        opacity: 1;
        visibility: visible;
        transform: scaleX(1);
        @media all and (max-width: 768px) {
            opacity: 1;
        }
    }
    @media all and (max-width: 768px) {
        left: 0;
    }
}

</style>
