import Vue from 'vue'
import Router from 'vue-router'
import AuthService from "../services/AuthService";
import store from '../store';

Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    watch: {},
    routes: [
        {
            path: '/login',
            name: 'login',
            meta: {
                access: 'guest',
                title: 'Вхід | Платформа депутата'
            },
            component: () => import('../views/auth/Login.vue')
        },
        {
            path: '/registration/:token',
            name: 'registration',
            meta: {
                access: 'guest',
                title: 'Реєстрація | Платформа депутата'
            },
            component: () => import('../views/auth/Register.vue')
        },
        {
            path: '/',
            name: 'intro',
            meta: {
                access: '*',
                title: 'Платформа депутата'
            },
            component: () => import('../views/Home.vue')
        },
        {
            path: '/position',
            name: 'position',
            meta: {
                access: 'auth',
                title: 'Позиція партії | Платформа депутата'
            },
            component: () => import('../views/Position.vue')
        }, {
            path: '/position/:id',
            name: 'position.article',
            meta: {
                access: 'auth',
            },
            component: () => import('../views/PositionArticle.vue')
        },
        {
            path: '/business',
            name: 'business',
            meta: {
                access: 'auth',
                title: 'Щодо оцінки дій влади з боку бізнесу'
            },
            component: () => import('../views/Business.vue')
        },
        {
            path: '/cards',
            name: 'cards',
            meta: {
                access: 'auth',
                title: 'Картки депутатів'
            },
            component: () => import('../views/CardsOfDeputies.vue')
        },
        {
            path: '/cards-participant',
            name: 'cards-participant',
            meta: {
                access: 'auth',
                title: 'Картки партійців'
            },
            component: () => import('../views/CardsOfDeputies.vue')
        },
        {
            path: '/card/:id',
            name: 'card',
            meta: {
                access: 'auth'
            },
            component: () => import('../views/CardOfDeputy.vue')
        },

        {
            path: '/member/:id',
            name: 'member',
            meta: {
                access: 'auth'
            },
            component: () => import('../views/CardOfMember.vue')
        },

        {
            path: '/events',
            name: 'events',
            meta: {
                access: 'auth',
                title: 'Платформа депутата'
            },
            component: () => import('../views/Calendar.vue')
        },
        {
            path: '/base/:id?',
            name: 'base',
            meta: {
                access: 'auth',
                title: 'Дерево файлів'
            },
            component: () => import('../views/Base.vue')
        },
        {
            path: '/feedback',
            name: 'feedback',
            meta: {
                access: 'auth',
                title: 'Форма зворотного зв’язку'
            },
            component: () => import('../views/Feedback.vue')
        },
        {
            path: '/study',
            name: 'study',
            meta: {
                access: 'auth',
                title: 'Навчання | Платформа депутата'
            },
            component: () => import('../views/Study.vue')
        },
        {
            path: '/study/:id',
            name: 'study.index',
            meta: {
                access: 'auth',
            },
            component: () => import('../views/StudyCategory.vue')
        },
        {
            path: '/study/:category/course/:id',
            name: 'course.index',
            meta: {
                access: 'auth',
            },
            component: () => import('../views/StudyContent.vue')
        },
        {
            path: '/remind-password',
            name: 'remind-password',
            meta: {
                access: 'guest',
                title: 'Нагадати пароль.'
            },
            component: () => import('../views/auth/RemindPassword.vue')
        },
        {
            path: '/profile',
            name: 'profile',
            meta: {
                access: 'auth',
                title: 'Особистий кабінет'
            },
            component: () => import('../views/Profile.vue')
        },
        {
            path: '/achievements',
            name: 'achievements',
            meta: {
                access: 'auth',
                title: 'Особистий кабінет - мої досягнення'
            },
            component: () => import('../views/Achievements.vue')
        },
        {
            path: '/events/:id',
            name: 'event',
            meta: {
                title: 'Партійні заходи'
            },
            component: () => import('../views/Event.vue')
        },
        {
            path: '/ratings',
            name: 'ratings',
            meta: {
                title: 'Рейтинги | Платформа депутата'
            },
            component: () => import('../views/rating/Index.vue')
        },
        {
            path: '/search',
            name: 'search',
            meta: {
                title: 'Результат пошуку | Платформа депутата'
            },
            component: () => import('../views/Search.vue')
        }, {
            path: '/activity',
            name: 'activity',
            meta: {
                title: 'Контроль | Платформа депутата'
            },
            component: () => import('../views/Activity.vue')
        }, {
            path: '/activity/create/:type',
            name: 'activity_create',
            meta: {
                title: 'Контроль створити | Платформа депутата'
            },
            component: () => import('../views/ActivityCreate.vue')
        }, {
            path: '/activity/update/:type/:id',
            name: 'activity_update',
            meta: {
                title: 'Змінити контроль | Платформа депутата'
            },
            component: () => import('../views/ActivityCreate.vue')
        },
        {
            path: '/control',
            name: 'control',
            meta: {
                title: 'Контроль | Платформа депутата'
            },
            component: () => import('../views/Control.vue')
        },
        {
            path: '/control/create',
            name: 'control-create',
            meta: {
                title: 'Контроль | Платформа депутата'
            },
            component: () => import('../views/ControlForm.vue')
        },
        {
            path: '/detail-participant/:id',
            name: 'detail-participant',
            meta: {
                title: 'Партієць | Платформа депутата'
            },
            component: () => import('../views/DetailParticipant.vue')
        },

        {
            path: '/add-friends',
            name: 'add-friends',
            meta: {
                title: 'Запросити друзів'
            },
            component: () => import('../views/AddFriends.vue')
        },

        {
            path: '/voting',
            name: 'voting',
            meta: {
                title: 'Голосування | Платформа депутата'
            },
            component: () => import('../views/voting/Voting')
        },
        {
            path: '/voting/:id',
            name: 'voting-answer',
            meta: {
                title: 'Голосування | Платформа депутата'
            },
            component: () => import('../views/voting/VotingAnswer')
        },

        {
            path: '/voting-detail/:id',
            name: 'voting-detail',
            meta: {
                title: 'Результат голосування | Платформа депутата'
            },
            component: () => import('../views/voting/VotingDetail')
        },

        {
            path: '/encouragement',
            name: 'encouragement',
            meta: {
                title: 'Список подій'
            },
            component: () => import('../views/Encouragement'),
            children: [
                {
                    path: '/encouragement/:type',
                    name: 'encouragement-list',
                    component: () => import('../components/IncentivesList'),
                    meta: {
                        title: 'Список подій'
                    }
                },
                {
                    path: '/encouragement/user-project/create',
                    name: 'user-project-create',
                    component: () => import('../components/UserProject'),
                    meta: {
                        title: 'Я — реалізую партійні проєкти'
                    }
                },
                {
                    path: '/encouragement/user-project/update/:id',
                    name: 'user-project-update',
                    component: () => import('../components/UserProject'),
                    meta: {
                        title: 'Я — реалізую партійні проєкти'
                    }
                },
                {
                    path: '/encouragement/user-project/view/:id',
                    name: 'user-project-view',
                    component: () => import('../components/UserProjectView'),
                    meta: {
                        title: 'Я — реалізую партійні проєкти'
                    }
                },
                {
                    path: '/encouragement/active-participant/create',
                    name: 'active-participant-create',
                    component: () => import('../components/FormActiveParticipant'),
                    meta: {
                        title: 'Я — активний учасник партійних заходів'
                    }
                },
                {
                    path: '/encouragement/active-participant/view/:id',
                    name: 'active-participant-view',
                    component: () => import('../components/FormActiveParticipant'),
                    meta: {
                        title: 'Я — активний учасник партійних заходів'
                    }
                },
                {
                    path: '/encouragement/active-participant/update/:id',
                    name: 'active-participant-update',
                    component: () => import('../components/FormActiveParticipant'),
                    meta: {
                        title: 'Я — активний учасник партійних заходів'
                    }
                },
                {
                    path: '/encouragement/media-active/create',
                    name: 'media-active-create',
                    component: () => import('../components/FormMediaActive'),
                    meta: {
                        title: 'Я — медійно активний'
                    }
                },
                {
                    path: '/encouragement/media-active/update/:id',
                    name: 'media-active-update',
                    component: () => import('../components/FormMediaActive'),
                    meta: {
                        title: 'Я — медійно активний'
                    }
                },
                {
                    path: '/encouragement/volunteer/create',
                    name: 'volunteer-create',
                    component: () => import('../components/FormVolunteer'),
                    meta: {
                        title: 'Я — волонтер'
                    }
                },
                {
                    path: '/encouragement/volunteer/update/:id',
                    name: 'volunteer-update',
                    component: () => import('../components/FormVolunteer'),
                    meta: {
                        title: 'Я — волонтер'
                    }
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            meta: {
                access: '*',
                title: 'Нажаль, цієї сторінки не існує.'
            },
            component: () => import('../views/NotFound.vue')
        },

    ]
});

router.beforeEach(function(to, from, next) {
    document.title = to.meta.title;
    AuthService.get().then(res => {

        store.dispatch('setIdentity', res).then(() => {});
        if(to.meta.access === 'guest') {
            location.href = '/profile';
        }
        next();
    }).catch(err => {
        next();
        if(err.response.status === 403) {
            if(to.meta.access === 'auth' && to.name !== 'login') {
                window.location = '/login';
            }
        }
    });
})

router.afterEach(() => {
    store.dispatch('setShowNotFound', false).then();
})

export default router;
