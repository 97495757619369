<template>
    <div class="grey darken-1 intro-layout">
        <AuthNav v-if="auth"/>
        <nprogress-container></nprogress-container>
        <Menu v-if="auth"/>
        <transition :name="transitionName">
            <NotFound v-if="$store.getters.showNotFound"/>
            <router-view v-else></router-view>
        </transition>
    </div>
</template>


<script>
import messages from "../utils/messages";
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import Menu from "../components/Menu";
import AuthNav from "../components/AuthNav";
import NotFound from "../views/NotFound";

export default {
    components: {
        NotFound,
        Menu,
        NprogressContainer,
        AuthNav
    },
    data() {
        return {
            transitionName: 'slide-left',
            authBoolean: false
        };
    },
    computed: {
        error() {
            return this.$store.getters.error;
        },
        auth() {
            return !!Object.keys(this.$store.getters.identity).length;
        }
    },
    watch: {
        error(fbError) {
            this.$error(messages[fbError.code] || "Что-то пошло не так..");
        },
        $route(to, from) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        }
    },
    beforeCreate() {
        this.$store.state.isAuth = !!localStorage.getItem('auth');
    }
};
</script>

<style lang="less">
.child-view {
    position: absolute;
    transition: all .75s cubic-bezier(.55,0,.1,1);
}
.animated_page_out{
    transform: translateX(-1000px);
}
.slide-left-enter, .slide-right-leave-active {
    opacity: 0;
    transform: translate(30px, 0);
}
.slide-left-leave-active, .slide-right-enter {
    opacity: 0;
    transform: translate(-30px, 0);
}

section.wrapper{
    display: flex;
    @media all and (max-width: 950px) {
        display: block;
    }
    .sidebar, .content{
        overflow-y: auto;
        overflow-x: hidden;
    }
    .sidebar{
        width: 36%;
        max-width: 640px;
        @media all and (max-width: 950px) {
            max-width: 100%;
            width: 100%!important;
            overflow: visible;
            .body{
                .slot {
                    display: none;
                }

            }
        }
    }
    .content{
        padding: 116px 40px 40px;
        flex: 1 1 auto;
        background-color: #F5F5F5;
        width: 70%;
        min-height: 100vh;
        @media all and (min-width: 950px) {
            max-height: 100vh;
        }
        @media all and (max-width: 950px) {
            width: 100%;
            padding: 0 20px 20px;
        }
    }
}

.custom-scroll::-webkit-scrollbar {
    width: 2px;
}
.custom-scroll::-webkit-scrollbar-track {
    background: #ffc900;
}
.custom-scroll::-webkit-scrollbar-thumb {
    background: #1a2744;
    border-radius: 0;
}
.custom-scroll.scroll-reverse::-webkit-scrollbar-track {
    background: #1a2744;
}
.custom-scroll.scroll-reverse::-webkit-scrollbar-thumb {
    background: #ffc900;
}
</style>
