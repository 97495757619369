<template>
    <div class="app" id="app">
        <notifications position="bottom right" classes="my-notification"/>
        <component :is="layout">
            <router-view/>
        </component>
    </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
    computed: {
        layout() {
            return (this.$route.meta.layout ? this.$route.meta.layout : 'main') + "-layout";
        },
    },
    async created() {
        if(await this.$recaptchaLoaded()) {
            this.$recaptchaInstance.hideBadge();
        }

        const metas = document.getElementsByTagName('meta');

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === 'viewport') {
                if (screen.width > 768) {
                    let c = 1.5;
                    metas[i].setAttribute('content', `initial-scale=${c},user-scalable=no,maximum-scale=1,width=device-width`);
                }
                break;
            }
        }

        // viewport
    },
    components: {
        MainLayout
    }
};
</script>

<style lang="less">
.app{
    overflow-y: hidden;
}
html {
  scroll-behavior: smooth;
}

* {
    font-family: @defaultFontFamily;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    width: 100%;
    margin: 0 auto;
}

// notifications styles
.my-notification {
    // styling
    margin: 0 5px 5px;
    padding: 10px 10px;
    font-size: 14px;
    color: #ffffff;

    // default (blue)
    background: #44A4FC;
    border-left: 5px solid #187FE7;

    // types (green, amber, red)
    &.success {
        background: #68CD86;
        border-left-color: #42A85F;
    }

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        background: #E54D42;
        border-left-color: #B82E24;
    }
}

button, a {
    font-family: inherit;

    &:focus {
        outline: none;
    }
}

button {
    cursor: pointer;
    border: 0;
    background: none;
}
#nprogress .bar {
    background: #1a2744!important;
}
html {
    height: 100%;
}
body{
    min-width: 280px;
    height: 100%;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 950px) {
    input, textarea, select {
        font-size: 16px;
    }
}
</style>

