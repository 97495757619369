import axios from "axios";
import handleApiError from "@/services/handleApiError";

export default {
    get() {
        return axios.get('/api/user').then(resp => resp.data);
    },
    getUserForRegistration(token) {
        return axios.get('/api/user/signup', {params: {token: token}})
            .then(resp => resp.data.data).catch(handleApiError);
    },
    register(data) {
        return axios.post('/api/user/signup', data).then(resp => resp.data).catch(handleApiError);
    },
    login(data) {
        return axios.post('/api/user/login', data).then(resp => resp.data.data).catch(handleApiError);
    },
    sms(data) {
        return axios.post('/api/user/sms', data).then(resp => resp.data.data).catch(handleApiError);
    },
    resetPassword(data) {
        return axios.post('/api/user/reset-password', data).then(resp => resp.data.data).catch(handleApiError);
    },
    resetSms(data) {
        return axios.post('/api/user/reset-sms', data).then(resp => resp.data.data).catch(handleApiError);
    },
    newPassword(data) {
        return axios.post('/api/user/password', data).then(resp => resp.data).catch(handleApiError);
    },
}
