export default {
    state: () => ({
        identity: {}
    }),
    actions: {
        setIdentity({commit, state}, data) {
            commit('setIdentity', data);
        }
    },
    mutations: {
        setIdentity(state, data) {
            state.identity = data.user;
            state.identity.visible_admin = data.visible_admin;
        }
    },
    getters: {
        identity: state => {
            return state.identity;
        }
    }
}
